<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('deal.deal') }}</div>
              </div>
            </template>
            <div class="row">
              <div class="col-12">
                <octo-header-button
                  :title="registry.surname + ' ' + registry.name"
                  :buttons="headerButtons"
                  @onSaveDeal="saveDeal"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <deal-data-container :deal="deal"/>
                <deal-summary
                  ref="dealSummary"
                  show-deal-dates
                  show-extra-fields
                  :allowed-status="[
                    dealStatuses.not_interested.value,
                    dealStatuses.not_valid.value,
                    dealStatuses.recall.value,
                    dealStatuses.processing.value,
                    dealStatuses.closed.value
                  ]"
                  :deal="deal"
                  :key="`summary-${updateAfterMountedKey}`"/>
              </div>
              <div class="col-md-5">
                <deal-status-container
                  :deal="deal"
                  :key="`dealStatusContainer-${updateAfterMountedKey}`"
                />
                <deal-history :deal="deal" :key="`dealHistory-${updateAfterMountedKey}`"/>
              </div>
            </div>
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('callcenter.lead') }}</div>
              </div>
            </template>
            <div class="row">
              <div class="col-12">
                <octo-header-button :title="registry.surname + ' ' + registry.name"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <lead-card :lead="deal.lead"/>
              </div>
              <div class="col-md-6">
                <lead-history
                  :lead-id="deal.lead | optional('id')"
                  :key="`lead-history-${updateAfterMountedKey}`"
                />
              </div>
            </div>
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.registry') }}</div>
              </div>
            </template>
            <div class="row">
              <div class="col-12">
                <octo-header-button :title="registry.surname + ' ' + registry.name"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <registry-personal-data
                  save-self
                  :registry="registry"
                  :key="`data-${updateAfterMountedKey}`"
                />
                <octo-entity-addresses
                  ref="octoEntityAddresses"
                  save-self
                  :addresses="registry.addresses"
                  :registry-id="registry.id"
                  :key="`addresses-${updateAfterMountedKey}`"
                />
                <octo-entity-emails
                  save-self
                  :emails="registry.emails"
                  :registry-id="registry.id"
                  :key="`emails-${updateAfterMountedKey}`"
                />
                <octo-entity-phones
                  save-self
                  :phones="registry.phones"
                  :registry-id="registry.id"
                  :key="`phones-${updateAfterMountedKey}`"
                />
              </div>
              <div class="col-md-6">
                <registry-history
                  :key="`registry-history-${updateAfterMountedKey}`"
                  :registry-id="registry.id"
                  :timelines-in-page="1"/>
              </div>
            </div>

          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.courses') }}</div>
              </div>
            </template>
            <octo-header-button :title="registry.surname + ' ' + registry.name"/>
            <deal-contract-data-card
              :deal="deal"
              :key="`deal-datum-form-${updateAfterMountedKey}`"
              @onUpdateDealOnly="updateDealOnly"
            />
            <deal-courses-card
              :deal="deal"
              :key="`deal-courses-card-${updateAfterMountedKey}`"
              @onUpdateDealOnly="updateDealOnly"
            />
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.documents') }}</div>
              </div>
            </template>
            <octo-header-button :title="registry.surname + ' ' + registry.name"/>
            <deal-documents-updated :deal="deal" :key="`deal-documents-${updateAfterMountedKey}`"/>
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import {TabPane, Tabs} from '@/components';
import OctoIcon from "@/components/octo-icon/OctoIcon";
import OctoEntityEmails from "@/components/octo-entity-emails/OctoEntityEmails";
import OctoEntityAddresses from "@/components/octo-entity-addresses/OctoEntityAddresses";
import OctoEntityPhones from "@/components/octo-entity-phones/OctoEntityPhones";
import Deal from "@/models/deal";
import Registry from "@/models/registry";
import DealSummary from "./components/DealSummary";
import DealDocuments from "@/pages/Deals/components/DealDocuments";
import RegistryPersonalData from "../Registries/components/RegistryPersonalData";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import DealStatuses from "@/pages/Deals/resources/dealStatuses";
import DealStatusContainer from "@/pages/Deals/components/DealStatusContainer";
import DealSummaryGeneral from "@/pages/Deals/components/DealSummaryGeneral";
import DealHistory from "@/pages/Deals/components/DealHistory";
import RegistryHistory from "@/pages/Registries/components/RegistryHistory";
import DealDataContainer from "@/pages/Deals/components/DealDataContainer";
import LeadCard from "@/pages/Leads/components/LeadCard";
import LeadHistory from "@/pages/CallCenter/OperatorComponents/LeadHistory";
import DealContractDataCard from "./components/DealContractDataCard";
import DealCoursesCard from "./components/DealCoursesCard";
import DealDocumentsUpdated from "./components/DealDocumentsUpdated";

export default {
  name: "ShowDealInProgressPage",
  components: {
    DealDocumentsUpdated,
    DealCoursesCard,
    DealContractDataCard,
    LeadCard,
    DealDataContainer,
    DealHistory,
    DealSummaryGeneral,
    DealStatusContainer,
    OctoHeaderButton,
    RegistryPersonalData,
    DealDocuments,
    DealSummary,
    OctoEntityPhones,
    OctoEntityAddresses,
    OctoEntityEmails,
    OctoIcon,
    Tabs,
    TabPane,
    RegistryHistory,
    LeadHistory
  },
  data() {
    return {
      endpoints: endpoints,
      deal: this.$_.cloneDeep(Deal),
      registry: this.$_.cloneDeep(Registry),
      updateAfterMountedKey: 1,
      dealStatuses: DealStatuses,
      headerButtons: [{
        label: 'save',
        onClick: 'onSaveDeal'
      }],
    }
  },
  beforeMount() {
    this.$fullLoading.show();

    this.$api.get(endpoints.DEAL_SHOW.replace('{id}', this.$route.params.id))
      .then((resp) => {
        this.deal = resp?.data?.data;
        this.registry = this.deal?.registry;
        this.updateAfterMountedKey++;
        this.$fullLoading.hide();
      })
      .catch(() => {
        this.$fullLoading.hide();
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        this.$router.push({name: 'deals.in_progress'})
      })
  },
  methods: {
    saveDeal: async function () {
      try {
        const newStatus = this.$refs.dealSummary.getStatus();
        const newSubStatus = await this.$refs.dealSummary.getSubStatus();

        const data = {
          status: newStatus ? newStatus : this.deal.status,
          sub_status: newStatus ? newSubStatus : this.deal.sub_status,
          weekly_frequency: this.deal.weekly_frequency,
          classroom_info: this.deal.classroom_info,
          individual_course: this.deal.individual_course,
          text: this.deal.text,
        };

        this.$fullLoading.show();

        const resp = await this.$api.put(
          endpoints.DEAL_IN_PROGRESS_UPDATE.replace('{id}', this.deal.id),
          data
        );

        if (newStatus && newStatus !== this.deal.status) {
          await this.$router.push({name: 'deals.in_progress'});
        } else {
          this.deal = resp?.data?.data;
          this.registry = this.deal?.registry;
          this.updateAfterMountedKey++;
        }

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    updateDealOnly(deal) {
      this.deal = deal || this.$_.cloneDeep(Deal);
      this.updateAfterMountedKey++;
    },
  }
}
</script>

<style scoped>

</style>
