<template>
  <div>
    <div class="d-flex justify-content-center mb-3">
      <el-upload
        :auto-upload="false"
        drag
        ref="upload"
        :action="action"
        :on-success="handleSuccess"
        :before-upload="handleBeforeUpload"
        :http-request="httpRequest"
        :file-list="fileList"
        multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
      </el-upload>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <div class="flex-grow-1">
        <base-input v-if="tagList.length">
          <el-select
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('fields.choose')"
            v-model="fileToUpload.tag"
          >
            <el-option
              v-for="option in tagList"
              class="select-default text-uppercase"
              :value="option"
              :label="$t('filetag.' + option)"
              :key="option.value"
            />
          </el-select>
        </base-input>

      </div>
      <base-input class="ml-3">
        <base-button block link @click="startUpload()">
          <span class="text-uppercase">{{ $t('common.start_upload') }}</span>
        </base-button>
      </base-input>
    </div>
  </div>
</template>

<script>
import {Option, Select, Upload} from 'element-ui';
import OctoIcon from "@/components/octo-icon/OctoIcon";
import OctoUploadCloudMixin from "./OctoUploadCloudMixin";

export default {
  name: "OctoUploadCloud",
  components: {
    OctoIcon,
    [Upload.name]: Upload,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  mixins: [OctoUploadCloudMixin]
}
</script>

<style scoped>

</style>
